.custom-service-card {
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 20px;
  margin: 20px 0;
  text-align: center;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
}

.custom-service-card:hover {
  transform: translateY(-5px);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.custom-icon img {
  width: 64px;
  height: 64px;
}

.custom-title {
  font-size: 1.25rem;
}

.custom-description {
  color: #6c757d;
}

.Service_pricing {
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  color: #fff;
  border-color: #ff5c00;
  background: #ff5c00;
}

.Service_pricing:hover {
  color: #fff;
  border-color: transparent;
  background:#ff5c00;
  text-decoration: none !important;
}

.Service_call{
  border: 1px solid #ff5c00;
  text-decoration: none;
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  margin-left: 10px;
  transition: 0.6s all;
}

.Service_call:hover{
  color: white;
  border-color: #ff5c00;
  background: #f9bd9a
}

/* iPads in portrait and landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait),
       only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
.custom-service-card{
  min-height: 90% !important;
} 
}