.bg__size{
    background-color: white;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    border-radius: 8px;
}


.form-title, .form_house {
    margin-bottom: 20px;
    color: #ff5c00;
    text-align: start;
  }
  
  .form-label {
    display: block;
    font-size: 16px;
  }

  .size__radio{
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
  }

  input[type="radio"] {
    margin-right: 10px;
    transform: scale(1.3);

  }

  .size__listing{
    text-align: justify;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
  }

  .example-text{
    color: #98a2b3;
  }

  /* Media Queries for responsiveness */
@media (max-width: 768px) {
  .form_house p {
    margin-bottom: 5px;
  }

  .form_house a {
    display: block;
    margin-top: 5px;
  }

  .form-select {
    width: 100%;
    max-width: 100%;
  }

  .size__radio label,
  .size__listing label {
    margin-bottom: 10px;
  }

  .example-text {
    font-size: 0.8em;
  }

  .moving-location-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px;
    text-align: center;
}
.size__radio, .info-container{
  display: flex;
  flex-direction: column;
  align-items: start !important;
}
}

.info-text{
  color: #ff5c00;
}