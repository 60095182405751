@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
  --body-fonts: "Poppins", sans-serif !important;
  --heading-fonts: "Poppins", sans-serif !important;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  font-optical-sizing: auto;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.h1, .h2, .h3, .h4, h5, h6{
  font-family: var(--heading-fonts) !important;
}

::-webkit-scrollbar {
  width: 6px; 
}

::-webkit-scrollbar-thumb {
  background-color: #ff5c00; 
  border-radius: 10px; 
  border: 2px solid transparent; 
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; 
}
