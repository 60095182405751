.banner__image {
  background-image: url("../../Assests/banner.webp");
  background-size: cover;
  background-position: center;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skilled__content {
  display: flex;
  justify-content: space-between;
  width: 80%;
  color: white;
  padding: 20px;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.5); */
}

.text-section {
  flex: 1;
}

.gold-stars {
  color: gold;
  margin-left: 5px;
}

.custom-video {
  width: 100%;
  height: 80vh;
  /* object-fit: cover;  */
}

.information__justify {
  text-align: justify;
}

.removalist-card {
  border: 1px solid #ff5c00;
  padding: 20px;
  text-align: start;
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  border-radius: 0px 0px 0px 30px;
  min-height: 310px;
}

.removalist-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.removalist__icons {
  width: 60px;
  height: 60px;
  margin-bottom: 15px;
}

.callback__section {
  background-color: #0c2c36;
  /* background-color: #ff5c00;  */
  padding: 50px 0;
}


.remove_moveus {
  padding: 40px 0;
  background-image: url('../../Assests/banner.jpg'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; 
  min-height: 400px; 
  display: flex;
  align-items: center;
}

.remove_moveus .col-md-6 {
  position: relative;
}


.removalists__ease__move {
  background-color: #ff5c00;
  color: white;
  max-width: 400px;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.accordion-button:not(.collapsed) {
  background-color: #f9bd9a;
  color: #000;
}

.accordion-button:focus {
  box-shadow: none;
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 768px) {
  .skilled__content {
    flex-direction: column;
    text-align: center;
    width: 90%;
    padding: 10px;
  }

  .mobile__text {
    margin-bottom: 20px;
    text-align: left;
  }

  .mobile__location {
    width: 100% !important;
  }

  .mobile__banner__image {
    height: auto;
    padding: 20px 0;
  }

  .callback__section .callback__details {
    flex-direction: column;
    text-align: center;
  }
  .callback__freequote {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .removalist-card {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 412px) {
  .remove_moveus {
    background-image: none;
  }
}

@media (min-width: 768px) and (max-width: 820px) {
  .remove_moveus {
    background-size: contain;
    background-position: left;
  }
}
@media screen and (min-width: 768px) and (max-width: 768px) {
  .removalists__ease__move {
    padding: 10px !important;
  }
}


@media (min-width: 768px) and (max-width: 1023px) {
  .removalist-card {
    min-height: 400px;
    margin-bottom: 20px;
  }
  .skilled__movers__images{
    display: flex;
    justify-content: center;
  }
}

/* @media (min-device-width: 1024px) and (max-device-width: 1366px){
  .custom-video{
    height: 40vh;
  }
} */
