.container {
  max-width: 1200px;
  margin: auto;
}

.room-buttons {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.room-button {
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f0f0f0;
}

.room-button.active {
  background-color: #f3d4c3;
  border: 1px solid #ff5c00;
}

.room { 
  border: 1px solid #ddd;
  margin-bottom: 20px;
  padding: 10px;
  display: none;
}

.room.active {
  display: block;
  border: none;
}

.custom-items {
  margin-top: 20px;
}
.item-name,
.item-size {
  text-align: left;
  margin-bottom: 0;
}
.custom-item {
  /* border: 1px solid #ddd; */
  margin-bottom: 10px;
  /* padding: 10px;
  padding-left: 20px; */
}

.item {
  /* border-top: 1px solid #ddd; */
  padding-top: 10px;
}

#rooms-container {
  max-height: 400px; /* Adjust as needed */
  overflow-y: auto;
  /* padding-right: 15px; */
}
#rooms-container::-webkit-scrollbar {
  display: none;
}

.inventory__romms {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 11px;
  border-radius: 8px;
  background-color: white;
}


@media (max-width: 768px) {
  .total__inventory__truck{
    /* display: none; */
    font-size: 12px;
  }
  .here__quote__top{
    padding-top: 20px;
  }
}

@media (width: 768px) {
  .selected__nav__menus {
    padding-top: 10px;
  }
}

.custom-accordion-button {
  box-shadow: none !important;
  background-color: transparent; 
  border: none; 
}

.custom-accordion-button:focus,
.custom-accordion-button:active {
  box-shadow: none !important;
  outline: none;
}

.custom__items__accordin__list {
  border: none;
}

.custom-accordion-btn {
  margin-bottom: 0.5rem;
}
