  .contact-info-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  
  .contact-info-item {
    display: flex;
    align-items: center;
  }
  
  .contact-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* background-color: #e0e7ff; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  
  .contact-icon i {
    color: #ff5c00;
    font-size: 24px;
  }
  
  .contact-info-item p {
    margin: 0;
    color: #4a4a4a;
  }
  
  .contact-info-item a {
    color: #7e7e7e;
    text-decoration: none;
    transition: 0.6s all;
  }
  
  .contact-info-item a:hover {
    text-decoration: underline;
    color: #ff5c00;
  }
  
  .map-container {
    height: 100%;
    width: 100%;
  }
  
  @media (max-width: 768px) {
    .contact-info-container {
      padding: 20px;
    }
  
    .contact-info-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .contact-icon {
      margin-bottom: 10px;
    }
  }
  
  