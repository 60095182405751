.moving-location-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  /* padding: 20px; */
  text-align: center;
}

.moving-location-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.card-container {
  /* display: flex; */
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 20px;
}

.card-link {
  text-decoration: none;
  color: inherit;
  width: 100%;
  margin-bottom: 12px;
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: auto;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  justify-content: center;
  align-items: center;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  border-color: #ff5c00;
  cursor: pointer;
}

.card img {
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: contain;
  border-radius: 8px 8px 0 0;
}

.card-content {
  padding: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content h4 {
  margin-top: 0;
  font-size: 18px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-content p {
  margin-bottom: 10px;
  font-size: 14px;
}

.popular-btn {
  background: #ff5c00;
  color: white;
}

.popular-btn:hover {
  background: #ff5c00;
  color: white;
}

.inventory__use {
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ff5c00;
  border-color: #ff5c00;
  background: #fff;
}

.inventory__use:hover {
  color: #ff5c00;
  border-color: #ff5c00;
  background: #f6f8fe;
}

.most-popular {
  background-color: #e0e0e0;
  color: #333;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .card-link {
    width: calc(50% - 20px);
  }
  .content_inventory {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 576px) {
  .card-link {
    width: 100%;
  }
}

@media (max-width: 820px) {
  .card-link {
    flex: 1 1 100%;
  }
}

/* inventory */

.content_inventory {
  display: flex;
  justify-content: center;
  gap: 10px;
  /* margin: 20px; */
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
}

.inventory__continue {
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  color: #fff;
  border-color: #ff5c00;
  background: #ff5c00;
}

.inventory__continue:hover {
  color: #fff;
  border-color: transparent;
  background: #ff5c00;
  text-decoration: none !important;
}

.room-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.room-detail-row {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #ff5c00;
  border-radius: 8px;
  padding: 10px;
  background: #f3d4c3;
}

.room-detail-row:hover {
  background: white;
}

.room-label {
  flex: 1;
  text-align: left;
  margin-right: 10px;
  cursor: pointer;
}

.room-input {
  width: 50px;
  text-align: center;
  border: none;
  background-color: transparent;
  margin-left: 10px;
}

.room-button {
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  font-weight: 500;
  font-size: 14px;
  line-height: 10px;
  color: #ff5c00;
  border-color: #ff5c00;
  background: #fff;
  border-radius: 5px;
}

.room-button:hover {
  border-color: #ff5c00 !important;
  background-color: #f3d4c3;
  color: white;
}

.checkbox-row {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox-row input[type="checkbox"] {
  margin-right: 5px;
}
