.bg__footerContainer {
  background-color: #ffffff; 
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px 0; 
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); 
}

.footer-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 7%;
}

.next_link {
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  color: #fff;
  background: #ff5c00;
  border: 0px;
}

.next_link:hover {
  color: #fff;
  border-color: transparent;
  background: #ff5c00;
  text-decoration: none !important;
}

@media (max-width: 768px) {
  .footer-container {
    padding-right: 20px;
  }
}
