.footer {
    /* background-color: #151414; */
    color: #fff;
    padding: 40px 0;
  }
  
  .footer-icon {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .footer-icon svg {
    color: #fff;
  }
  
  .footer-text {
    text-align: center;
  }
  
  .footer-links ul {
    list-style-type: none;
    padding: 0;
  }
  
  .footer-links ul li {
    margin-bottom: 10px;
  }
  
  .footer-links ul li a {
    color: #7e7e7e;
    text-decoration: none;
    transition: color 0.6s all;
  }
  
  .footer-links ul li a:hover {
    color: #ff5c00;
  }
  
  
  
  .footer-contact h5 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
  
  .social-icons {
    margin-top: 10px;
  }
  
  .social-icons a {
    display: inline-block;
    margin-right: 10px;
    color: black;
    transition: color 0.3s;
  }
  
  
  .description{
    margin-bottom: 14px;
    font-size: 16px;
    color: #7e7e7e;
    line-height: 28px;
  }
  
  .divider {
    height: 2px;
    background-color: #ff5c00; 
    margin: 10px 0 15px; 
    width: 20%;
  }
  
  .conatctmail:hover {
    color: #ff5c00 !important; 
    text-decoration: underline !important;
    transition: color 0.3s !important;
  }

  .citylist:hover {
    color: #ff5c00 !important; 
    transition: color 0.3s !important;
  }