.testimonial-section {
  background-image: url('../../Assests/ChatBackground.webp');
  background-size: contain;
  background-position: center;
}

.testimonial-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #000;
  color: white !important;
}

.quote-icon-card {
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  font-size: 2rem;
  color: white;
}

.blockquote {
  font-size: 1rem;
  line-height: 1.5;
}

.blockquote-footer {
  font-size: 18px;
  color: white;
  font-weight: bold;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.carousel-indicators {
  bottom: 10px;
}

.carousel-indicators .active {
  background-color: #ff5c00;
}

.Testminioal_heading {
  font-size: 60px;
  font-weight: bold;
}

.highlight {
  color: #ff5c00;
  text-shadow: 2px 2px #1f1010;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .Testminioal_heading {
    font-size: 40px;
  }

  .blockquote {
    font-size: 0.875rem;
  }

  .blockquote-footer {
    font-size: 16px;
  }

  .testimonial-card {
    padding: 2rem 1rem;
  }

  .carousel-container {
    margin-top: 2rem;
  }
  .quote-icon-card{
    font-size: 1rem;

  }
}

@media (max-width: 576px) {
  .Testminioal_heading {
    font-size: 30px;
  }

  .testimonial-card {
    padding: 1.5rem 1rem;
  }

  .carousel-container {
    margin-top: 1rem;
  }

  .carousel-item img {
    width: 50% !important;
  }
}
