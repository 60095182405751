.carousel-container {
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.carousel-slide.active {
  opacity: 1;
}

.moving-form {
  z-index: 2;
  max-width: 600px;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  position: relative;
  /* margin-left: 38%;  */
}

.moving-form h1 {
  margin-bottom: 20px;
  color: #2c2c2c;
}

.form-group-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  /* justify-content: center; */
}

.form-group {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  /* justify-content: center; */
}

.icon {
  position: absolute;
  left: 40px;
  color: #555;
  top: 50%;
  transform: translateY(-50%);
}

.quote-button {
  padding: 15px 20px; 
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
  border-radius: 8px;
  border-color: #ff5c00;
  background: #ff5c00;
}

.quote-button:hover {
  color: #fff;
  border-color: transparent;
  background: #ff5c00;
}

@media (max-width: 768px) {
  .carousel-container {
    height: 50vh;
  }

  .moving-form {
    width: 100%;
    max-width: none;
    margin-left: 0; 
  }
}

