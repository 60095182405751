.your-items-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
  }
  
  .header h2 {
    font-size: 20px;
    font-weight: bold;
  }
  
  .items-added {
    font-size: 14px;
    color: #888;
  }
  
  .search-container {
    position: relative;
    margin-bottom: 10px;
  }
  
  .search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: #888;
  }
  
  .search-input {
    width: 100%;
    padding: 10px 10px 10px 30px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .custom-item-link {
    color: #ff5c00;
    display: block;
    margin-bottom: 10px;
    text-align: start;
    text-decoration: none;
    cursor: pointer;
  }
  
  .toggle-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .toggle-label {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  .toggle-label input {
    display: none;
  }
  
  .toggle-slider {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 14px;
    margin-left: 8px;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
  }
  
  .toggle-slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: -3px;
    bottom: -3px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }
  
  .toggle-label input:checked + .toggle-slider {
    background: #ff5c00;
}
  
  .toggle-label input:checked + .toggle-slider:before {
    transform: translateX(16px);
  }
  
  .category {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 10px;
    text-align: start;
  }
  
  .category.disabled {
    display: none;
  }
  
  .items-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    /* border-bottom: 1px solid #ddd; */
  }
  
  .item-info {
    display: flex;
    flex-direction: column;
  }
  
  .item-name {
    font-size: 16px;
    font-weight: 400;
  }
  
  .item-volume {
    font-size: 14px;
    color: #888;
    text-align: start;
  }
  
  .item-controls {
    display: flex;
    align-items: center;
  }
  
  .item-count {
    font-size: 14px;
    margin: 0 10px;
  }

  .list__category{
    border: 1px solid #ddd;
    padding: 10px !important;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  
  .custom-item-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .custom-item-form .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .custom-item-form .form-row {
    display: flex;
    gap: 1rem;
  }
  
  .custom-item-form .form-row .form-group {
    flex: 1;
  }
  
  .custom-item-form .form-group label {
    margin-bottom: 0.5rem;
    font-weight: 600;
  }
  
  .custom-item-form .form-group input,
  .custom-item-form .form-group select {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .custom-item-form .form-group p {
    margin-top: 1rem;
    font-weight: 600;
  }
  
  .map_done {
    background-color: #ff5757;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
  }
  
  .map_done:hover {
    background-color: #ff4444;
  }
  
  @media (max-width: 768px) {
    .features__skilled__total {
      flex-direction: column !important;
      gap: 10px;
      align-items: start !important;
    }
  }