.faq-image {
  max-width: 50px;
  margin-right: 20px;
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.faq-item {
  margin-bottom: 20px;
}

.accordion .accordion-item {
  margin-bottom: 20px;
}

.accordion .accordion-header {
  font-size: 1.2rem;
  color: #333;
}

.accordion .accordion-body {
  font-size: 1rem;
  color: #555;
}
@media (max-width: 768px) {
  .faq-image {
    margin-right: 10px;
  }

  .faq-heading {
    font-size: 1.2rem;
  }

  .faq-item h3 {
    font-size: 1.1rem;
  }

  .faq-item p {
    font-size: 0.9rem;
  }
  .accordion .accordion-header {
    font-size: 1.1rem;
  }

  .accordion .accordion-body {
    font-size: 0.9rem;
  }
}
