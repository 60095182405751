.privacy_and_policy_text {
  color: white;
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

@media (max-width: 767px) {
  .privacy__subpoints {
    padding-inline-start: 0px !important;
  }
}
