.upload-container {
  padding: 20px;
}

.upload-section {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  height: 100%;
}

.upload-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.upload-subtitle {
  font-size: 16px;
  margin-bottom: 20px;
}

.photos-count {
  font-size: 16px;
  margin-bottom: 10px;
}

.notes-section {
  margin-bottom: 20px;
}

.notes-label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

.notes-input {
  width: 100%;
  height: 80px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
}

.save-notes-container {
  text-align: right;
}

.save-notes-button {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  color: #fff;
  background: #ff5c00;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
}

.save-notes-button:hover{
    color: #fff;
    border-color: transparent;
    background: #ff5c00;
    text-decoration: none !important;
}

.upload-box {
  border: 1px dashed #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.upload-label {
  display: block;
  cursor: pointer;
}

.upload-icon {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.upload-text {
  color: #8e4fe9;
  margin-bottom: 5px;
}

.upload-help {
  text-align: center;
}

.upload-help a {
  color: #8e4fe9;
  text-decoration: none;
  display: block;
  margin-top: 5px;
}
