.access-properties {
  padding: 20px;
  background: linear-gradient(180deg, #edeffd, #f8f9ff);
}

/* .nav-link {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  color: black;
}


.nav-link.active {
  background: linear-gradient(90deg, #c91500, #ff412b) !important;
  color: white;
  border-radius: 5px;
}

.nav-link.active:hover {
  background: linear-gradient(90deg, #ff412b, #ff412b);
  color: white;
}

.tab-pane {
  padding: 20px;
  border-radius: 8px;
  border-top: none;
} */

.border__content {
  border: 1px solid #ddd !important;
  border-radius: 8px;
  padding: 23px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.tab-content {
  /* border: 1px solid #ddd; */
  border-left: none;
}

form {
  margin-top: 20px;
}

/* .form-group {
  margin-bottom: 20px;
} */

.form-group label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
}

.radio-label {
  display: flex;
  align-items: center;
  /* margin-bottom: 10px; */
}

.radio-label:hover {
  /* background-color: #f6f8fe; */
  cursor: pointer;
}

.radio-label input[type="radio"] {
  margin-right: 10px;
  transform: scale(1.3);
  cursor: pointer;
}

textarea {
  width: 100%;
  min-height: 100px;
  resize: vertical;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

h5 {
  font-weight: bold;
}

h6 {
  font-weight: normal;
  color: #ff5c00;
  margin-bottom: 20px;
}

i.fas.fa-map-marker-alt {
  color: #ff5c00;
  margin-right: 5px;
}

.selected_form__group {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  display: inherit;
  border-radius: 8px;
}

.sticky-nav {
  position: fixed;
}

.radio-container .horizontal-line,
.radio-container .vertical-line {
  position: absolute;
  background-color: #ddd;
}

/* .radio-container .horizontal-line {
  height: 1px; 
  width: 100%;
  top: 48%;
} */

/* Myquote.css */
.nav-link {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  color: black;
}

.nav-link.active {
  background: #ff5c00 !important;
  color: white;
  border-radius: 5px;
}

.nav-link.active:hover {
  background: linear-gradient(90deg, #ff412b, #ff412b);
  color: white;
}

.tab-pane {
  padding: 20px;
  border-radius: 8px;
  border-top: none;
}

.mobile-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mobile-navigation button {
  padding: 10px 20px;
  background: transparent;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.mobile-navigation button:disabled {
  background: transparent;
  cursor: not-allowed;
}

.tab-label {
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: black;
}

@media (min-width: 768px) {
  .mobile-navigation {
    display: none;
  }
}

@media (max-width: 767px) {
  .nav {
    display: none !important;
  }

  .tab-pane {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .tab-pane {
    padding: 0px;
  }
  .heading__text__skilled{
    font-size: 18px !important;
  }
  .button__text__skilled{
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .parent {
    padding: 12px !important;
  }
  .locations_tabbs {
    padding-bottom: 10% !important;
  }
}

@media (max-width: 412px) {
  .locations_tabbs {
    padding-bottom: 20% !important;
  }
}

/* Location.css */

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's above other content */
}

.loading-content {
  text-align: center;
  color: #fff; /* White text for contrast */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-text {
  margin-top: 10px; /* Space between spinner and text */
  font-size: 16px; /* Adjust font size if needed */
  font-weight: 400; /* Adjust text weight if needed */
}
