.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.myquote_tab {
  background: transparent;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease;
}

.myquote_tab.active {
  color: #1d2939;
}

.myquote_tab.active::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 50%;
  height: 3px;
  background-color: #ff5c00;
  border-radius: 2p;
}

.bg__myquote {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 20px auto;
  max-width: 1000px;
}

.Myquote_booking {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d2939;
  padding: 10px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.reserve__booking {
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  color: #fff;
  border-color: #ff5c00;
  background: #ff5c00;
}

.reserve__booking:hover {
  color: #fff;
  border-color: transparent;
  background: #ff5c00;
  text-decoration: none !important;
}

.my_quote_address {
  border: 1px solid #ddd;
  padding: 10%;
  background-color: #fcfcfd;
  border-radius: 8px;
}

.solid_quote {
  color: #98a2b3;
}

.text-center {
  text-align: center;
}

.mb-0 {
  margin-bottom: 0;
}

.pt-3 {
  padding-top: 1rem;
}

.fs-5 {
  font-size: 1.25rem;
}

.font-weight-bold {
  font-weight: bold;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

hr {
  border: 0;
  border-top: 1px solid #d0d5dd;
  margin: 1rem 0;
}

a.text-decoration-none {
  color: #ff5c00;
  font-size: 17px;
  cursor: pointer;
}

a.text-decoration-none:hover {
  text-decoration: underline;
}

.quote_image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  padding: 3px;
}

.apply_button {
  border: 1px solid #ff5c00;
  background: white;
  border-radius: 4px;
  text-align: center;
  color: #3d348b !important;
  font-size: 16px;
  width: 20%;
}
.apply_button:hover {
  background-color: #e5d8f8;
}

.okay_myquote {
  background-color: #ff5c00;
  border-color: #ff5c00;
}

.okay_myquote:hover {
  color: #fff;
  border-color: #ff5c00;
  background: #ff5c00;
}

.cancel_myquote {
  border-color: #ff5c00;
  color: white;
  background-color: #ff5c00;
}

.cancel_myquote:hover {
  background-color: #ff5c00;
  color: white;
  border-color: #ff5c00;
}

.map_done {
  color: #fff;
  border-color: #ff5c00;
  background: #ff5c00;
}

.map_done:hover {
  color: #fff;
  border-color: transparent;
  background: #ff5c00;
  text-decoration: none !important;
}

.unique_cheaper_time .unique_cheaper_date_box {
  cursor: pointer;
  border: 1px solid #e0e0e0;
  transition: all 0.3s;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
}

.unique_cheaper_time .unique_cheaper_date_box.unique_selected {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.unique_date_day {
  font-weight: bold;
}

.unique_date_date {
  margin-top: 5px;
}

.unique_date_price {
  margin-top: 5px;
  font-size: 0.9rem;
}

.unique_date_additional {
  margin-top: 5px;
  font-size: 0.8rem;
}

.unique_map_done {
  background-color: #007bff;
  color: white;
  border: none;
}

.unique_cancel_myquote {
  background-color: #6c757d;
  color: white;
  border: none;
}

.assembly_from {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.assembly_bg {
  background: #fcfcfd;
  border-radius: 8px;
  border: 1px solid #ddd;
  padding: 15px;
}

@media (max-width: 768px) {
  .bg__myquote {
    padding: 15px;
  }

  .Myquote_booking {
    flex-direction: column;
    align-items: flex-start;
  }

  .reserve__booking {
    /* margin-top: 10px; */
    width: 100%;
    text-align: center;
  }
  .contact__details {
    display: block !important;
  }
  .feature {
    /* flex-direction: column; */
    align-items: center;
    text-align: center;
    width: 40% !important;
  }

  .check-icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .feature-text h3 {
    font-size: 13px !important;
  }
  .join_myquote p {
    font-size: 14px !important;
  }
  .contact-or {
    display: block;
  }
  .savings-box {
    padding: 0.75rem; /* Reduce padding on small screens */
    text-align: center; /* Center content for small screens */
  }

  .savings-number {
    font-size: 1.5rem; /* Adjust number size for small screens */
  }

  .savings-heading {
    font-size: 1.1rem; /* Adjust heading size for small screens */
  }

  .savings-detail {
    font-size: 0.9rem; /* Adjust detail text size for small screens */
  }
  .inverntory_customer_email {
    overflow: hidden;
  }
  .pricing-table {
    overflow-y: auto;
  }
  .breakdown__data {
    overflow-y: auto;
  }
}

.join_myquote {
  text-align: center;
}

.join_myquote h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.join_myquote p {
  font-size: 18px;
  margin-bottom: 20px;
}

.features {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.feature {
  display: flex;
  align-items: center;
  width: 30%;
  margin-bottom: 20px;
}


.check-icon {
  color: #ff5c00;
  font-size: 24px;
  margin-right: 10px;
}

.feature-text {
  text-align: left;
}

.feature-text h3 {
  font-size: 20px;
  margin: 0;
}

.feature-text p {
  margin: 5px 0 0;
}

.estimated_time_cost_total_myquote {
  text-align: center;
}

.estimated_time_cost_total_myquote h3 {
  color: #1f1f1f;
  margin-bottom: 10px;
}

.estimated_time_cost_total_myquote .details {
  display: block;
  margin: 5px 0;
}

.estimated_time_cost_total_myquote .minimum {
  color: #b0b0b0;
}

.estimated_time_cost_total_myquote .includes {
  display: block;
  color: #b0b0b0;
}

.estimated_time_cost_total_myquote .highlight {
  background-color: #ff5c00;
  color: white;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
}

.estimated_time_cost_total_myquote .highlight-detail {
  display: block;
  margin: 10px 0;
}

.estimated_time_cost_total_myquote .highlight h2 {
  margin: 10px 0;
  text-shadow: none;
}

.estimated_time_cost_total_myquote .highlight span {
  text-shadow: none;
}

.estimated_time_cost_total_myquote .note {
  display: block;
  color: #1f1f1f;
  margin-top: 20px;
}

.estimated_time_cost_total_myquote .note a {
  color: #6c63ff;
  text-decoration: none;
}

.estimated_time_cost_total_myquote .breakdown {
  color: #4c47e1;
  text-decoration: none;
  display: block;
  margin-top: 10px;
  cursor: pointer;
}

.breakdown__topfield td,
.breakdown__topfield tr {
  text-align: center;
}

.contact-section {
  text-align: center;
}

.contact-box {
  display: inline-block;
  text-align: center;
}

.contact-button_myquote {
  border: 1px solid #ff5c00;
  padding: 10px 20px;
  border-radius: 5px;
  color: #ff5c00;
  font-weight: bold;
  background-color: transparent;
  text-decoration: none;
  display: inline-block;
  margin: 0 auto;
  transition: 0.6s all;
}

.contact-button_myquote:hover {
  background-color: #f3d4c3;
}

.contact-hours {
  margin-top: 10px;
  font-size: 16px;
  color: #292767;
}

.contact-or {
  margin: 0 20px;
  font-weight: bold;
}

.contact-note {
  color: #2d2d2d;
  margin-top: 20px;
}

.section-divider {
  border-top: 1px solid #e5e5e5;
  margin: 20px 0;
}

.savings-title {
  font-size: 24px;
  font-weight: 500;
  color: #ff5c00;
  margin-bottom: 15px;
}

.savings-description {
  color: #2d2d2d;
  margin-bottom: 20px;
}

.savings-options {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.savings-box {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  max-width: 300px;
}

.savings-number {
  font-size: 24px;
  color: #ff5c00;
}

.savings-content {
  text-align: left;
}

.savings-heading {
  font-size: 18px;
  font-weight: 400;
  color: #2d2d2d;
  margin-bottom: 5px;
}

.savings-detail {
  color: #2d2d2d;
}

.savings-note {
  color: #2d2d2d;
  margin-top: 20px;
}

table thead tr th {
  border-bottom: none;
  border-top: 1px solid #98a2b3;
}

table thead tr th:first-child {
  background-color: #fff;
  border-top: none;
}

table thead tr th:nth-child(2) {
  border-top-left-radius: 16px;
  border-left: 1px solid #98a2b3;
  background-color: #f3d4c3;
  border-top: none;
  border-left: none;
}

table thead tr th:nth-child(3),
table thead tr th:nth-child(4) {
  border-left: 1px solid #98a2b3;
  background: #ff5c00;
}

table thead tr th:last-child {
  border-top-right-radius: 16px;
  border-right: 1px solid #98a2b3;
}

table thead tr th {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  border-color: #98a2b3;
  text-align: center;
}

table tbody tr td {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  border-color: rgba(48, 48, 48, 0.10196);
  text-align: center;
}

table tbody tr:first-child td:first-child {
  border-top-left-radius: 16px;
  overflow: hidden;
  background-color: #ff5c00;
  border-top: none;
  border-left: none;
  color: white;
}

table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 16px;
  background-color: #ff5c00;
  border-bottom: none !important;
  border-left: none;
  color: white;
}

table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 16px;
  background-color: #f3d4c3;
  border-bottom: none !important;
  border-right: none;
  color: black;
  font-weight: bold;
}

table tbody tr:first-child td {
  border-top: 1px solid rgba(48, 48, 48, 0.10196);
}

table tbody tr td:not(:first-child) {
  border-left: 1px solid #d9d9d9;
}

table tbody tr td:first-child {
  border-left: 1px solid rgba(48, 48, 48, 0.10196);
  border-bottom: 1px solid rgba(48, 48, 48, 0.10196);
  text-align: left;
}

table tbody tr td:not(:first-child) {
  border-left: 1px solid #d9d9d9;
}

table tbody tr td:last-child {
  border-right: 1px solid #98a2b3;
}

table tbody tr td:not(:first-child) {
  text-align: center;
}

table tbody tr td {
  vertical-align: top;
}

table tbody tr:first-child td {
  border-top: 1px solid #f3d4c3;
}

table tbody tr:nth-last-child(2) td {
  border-bottom: none;
}

table tbody tr:last-child td {
  border-top: 1px solid #98a2b3;
  border-bottom: 1px solid #98a2b3;
}

table tbody tr td {
  border-bottom: none;
  padding-bottom: 0;
}

tbody tr:last-child td:last-child {
  background-color: #33397b;
  color: #fff;
  font-weight: 500;
}

table thead tr th:last-child {
  border-top-right-radius: 16px;
  border-right: 1px solid #98a2b3;
  border-top: none;
  border-right: none;
  background-color: #f3d4c3;
}

tbody tr td {
  border-bottom: 1px solid #98a2b3 !important;
  /* background-color: #33397b; */
}

tbody tr:first-child td {
  border-bottom: none !important;
}

thead tr th:not(:first-child) {
  border-top: 1px solid #98a2b3;
}

.table.table__data tbody tr td.total-estimate {
  border-top: 1px solid #98a2b3;
  background: #f3d4c3;
  color: black;
  font-weight: bold;
  border-bottom: none !important;
}

.table.table__data tbody tr:nth-child(8) td:nth-of-type(3) {
  background: #ff5c00 !important;
  color: white;
  font-weight: bold;
  border-bottom: none !important;
}

.my__quote__tabledata {
  background: #f3d4c3 !important;
}

.pricing-table {
  max-width: 800px;
  margin: auto;
  font-family: Arial, sans-serif;
}

.pricing-header {
  text-align: left;
  padding-bottom: 10px;
}

.pricing-header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.pricing-header p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}

.table-row {
  display: table-row;
}

.table-cell {
  display: table-cell;
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
  /* color:"black" */
}

.header .table-cell {
  font-weight: bold;
  background-color: #f9f9f9;
}

.header .table-cell span {
  display: block;
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

.footer .table-cell {
  /* font-weight: bold; */
}

.table-cell:first-child {
  text-align: left;
  background-color: #f9f9f9;
}

.table-cell:last-child {
  background-color: #f1f1f1;
}
