.thankyou-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .thankyou-icon {
    font-size: 50px; 
    color: #ff5c00; 
    margin-bottom: 10px;
  }
  