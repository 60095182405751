.quote-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quote-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.quote-form-title {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.quote-form-group {
  margin-bottom: 20px;
  text-align: left;
}

.quote-form-label {
  display: block;
  color: #333;
  margin-bottom: 5px;
}

.quote-form-input,
.quote-form-select {
  display: block;
  width: calc(100% - 20px);
  margin-top: 5px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 5px;
  /* margin: 0 auto; */
}

.quote-form-checkbox {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.quote-form-checkbox input {
  margin-right: 10px;
}

.quote-form-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ff5c00;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.quote-form-button:hover {
  background-color: #ff5c00;
}

.quote-form .promise {
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  margin-top: 20px;
  justify-content: center;
}

.quote-form .promise-icon {
  flex-shrink: 0;
  margin-right: 10px;
}

.quote-form .promise-icon img {
  width: 50px;
  height: 50px;
}

.quote-form .promise-text {
  text-align: left;
}

.quote-form .promise-text h3 {
  margin: 0;
  color: #333;
}

.quote-form .promise-text p {
  margin: 5px 0 0;
  color: #666;
}

@media (max-width: 480px) {
  .quote-form {
    padding: 15px;
  }

  .quote-form-title {
    font-size: 24px;
  }

  .quote-form-label {
    font-size: 12px;
  }

  .quote-form-input,
  .quote-form-select {
    font-size: 12px;
    padding: 8px;
  }

  .quote-form-button {
    font-size: 14px;
    padding: 8px;
  }
}

.next_link:disabled {
  background: linear-gradient(90deg, #f8bc99, #f8bc99);
  color: #fff !important;
  cursor: not-allowed !important;
  border: 0px !important;
}

.details__logo{
  color: #40e550;
}