.form-group-custom {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

#card-element {
  border: 1px solid #ced4da;
  padding: 10px;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
}

.form-control-custom {
  border: 1px solid #ced4da;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}
