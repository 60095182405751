.inventory-container {
    padding: 20px;
    border-radius: 8px;
  }
  
  .inventory-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
  }
  
  .inventory-header h2 {
    font-size: 24px;
    font-weight: bold;
  }
  
  .total-volume {
    font-weight: bold;
    color: #888;
  }
  
  .room {
    margin-bottom: 20px;
  }
  
  .room h3 {
    font-weight: bold;
    color: #4A4A4A;
  }
  
  .item-count {
    font-size: 16px;
    color: #999;
  }
  
  .items-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 16px
  }
  
  .items-list li {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }
  
  .total-volume-room {
    font-weight: bold;
    color: #333;
  }
  
  /* Mobile Styles */
@media (max-width: 768px) {
  .inventory-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .total-volume {
    font-size: 1rem;
    margin-top: 0.5rem;
  }

  .room h3 {
    font-size: 1.2rem;
  }

  .items-list .inventory_feed {
    flex-direction: column;
    align-items: flex-start;
  }

  .items-list li span {
    margin-bottom: 0.25rem;
  }
  .handle__invntory{
    padding: 8px;
  }
}

.mobile__inventory__quick{
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; 
}