.move-form {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.move-form h2,
.move-form h3,
.move-form h4 {
  color: #333;
  margin-bottom: 10px;
}

.calendar {
  margin-bottom: 20px;
}

.custom-calendar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.custom-calendar .react-datepicker__month-container {
  width: 100%;
}

.custom-calendar .react-datepicker__day--disabled {
  color: #d3d3d3;
}

.custom-calendar .react-datepicker__day--disabled:hover {
  background-color: #f9f9f9; 
}

.arrival-time,
.assembly,
.additional-info,
.file-upload {
  margin-top: 20px;
}

.radio-group label {
  display: block;
  color: #555;
  font-size: 16px;
  cursor: pointer;
}

.schedule__group {
  display: flex;
  justify-content: space-around;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  align-items: center;
}

.assembly_options {
  display: flex;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  justify-content: space-around;
}

.radio-group input {
  margin-right: 10px;
}

.additional-info p {
  margin: 10px 0;
  color: #666;
}

.additional-info textarea {
  width: 100%;
  height: 80px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 10px;
  font-size: 16px;
}

.file-upload {
  margin-top: 20px;
}

.file-upload h3 {
  margin-bottom: 10px;
}

.upload-box {
  padding: 20px;
  border: 2px dashed #bbb;
  border-radius: 5px;
  text-align: center;
  background-color: #f9f9f9;
  cursor: pointer;
}

.upload-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.file-upload-label {
  color: #007bff;
  font-size: 16px;
}

.file-input {
  display: none;
}

.react-datepicker {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  background-color: #fff;
}

.react-datepicker__header {
  background-color: #f5f7fb;
  border-bottom: 1px solid #e0e0e0;
}

.react-datepicker__current-month {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.5rem;
  line-height: 2.5rem;
  font-size: 14px;
  color: #333;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: rgb(185, 220, 251);
  color: #fff;
}

.react-datepicker__day--today {
  color: black;
}

.react-datepicker__month-container {
  padding: 10px;
}

.react-datepicker__day--outside-month {
  color: #ccc;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .custom-calendar {
    flex-direction: column;
  }

  .custom-calendar .react-datepicker__month-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .schedule__group,
  .assembly_options {
    flex-direction: column;
    align-items: flex-start;
  }

  .radio-group label {
    width: 100%;
    margin-bottom: 10px;
  }

  .upload-box {
    padding: 10px;
  }

  .upload-icon {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__day,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
    font-size: 12px;
  }
  .properties__date{
    padding: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .custom-calendar {
    flex-direction: column;
  }

  .custom-calendar .react-datepicker__month-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .schedule__group,
  .assembly_options {
    flex-direction: column;
    align-items: flex-start;
  }

  .radio-group label {
    width: 100%;
    margin-bottom: 10px;
  }

  .upload-box {
    padding: 10px;
  }

  .upload-icon {
    width: 30px;
    height: 30px;
    margin-bottom: 5px;
  }

  .file-upload-label {
    font-size: 14px;
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__day,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
    font-size: 12px;
  }
  
}

.date__timebg{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

