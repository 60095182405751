.mover_image {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.mover_check{
    color: #8e4fe9;
    font-size: 24px;
    margin-right: 10px;
}

.become_details{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 20px;
    border-radius: 10px;
}   

.become_submit {
    padding: 0.5rem 1rem;
    text-decoration: none;
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    color: #fff;
    border-color: #8e4fe9;
    background: #8e4fe9;
  }
  
  .become_submit:hover {
    color: #fff;
    border-color: transparent;
    background: #8e4fe9;
    text-decoration: none !important;
  }