.IncrementNumber-container {
  text-align: center;
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.IncrementNumber-container h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.IncrementNumber-container p {
  font-size: 18px;
  margin-bottom: 40px;
  color: #666;
}

.IncrementNumber-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.counter-card {
  background: white;
  padding: 20px;
  margin: 10px;
  width: 200px; /* Increased width */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.counter-card:hover {
  transform: scale(1.05);
}

.IncrementNumber-count {
  font-size: 36px;
  font-weight: bold;
  color: #8e4fe9;
  margin-bottom: 10px;
}

.IncrementNumber-title {
  font-size: 16px;
  color: #333;
  margin-bottom: 10px; /* Space for the logo */
}

.IncrementNumber-logo {
  width: 30px;
  height: 30px;
  color: #40e550;
}


@media (max-width: 768px) {
  .counter-card {
    width: 320px; 
  }
}