.status-paid {
    background-color: green !important;
    color: white;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
    width: fit-content;
  }
  
  .status-unpaid {
    background-color: red !important;
    color: white;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    font-weight: bold;
    text-align: center;
    width: fit-content;
  }

  #scrollable-complete{
    max-height: 400px; /* Adjust as needed */
    overflow-y: auto;
    padding-right: 15px;
  }
  #scrollable-complete::-webkit-scrollbar {
    display: none;
  }
  
  .profile__list{
    background-color: white;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 8px;
  }
