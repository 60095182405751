.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand img {
  margin-right: 10px;
}

.navbar.scrolled {
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.navbar {
  background-color: white !important;
  transition: box-shadow 0.3s ease-in-out;
}

.navbar .nav-link {
  color: #000;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
}

.navbar .nav-link:hover {
  color: black;
  text-decoration: underline !important;
  text-decoration-color: #ff5c00 !important;
  text-decoration-thickness: 2px;
  text-underline-offset: 5px;
}

.navbar .nav__links {
  color: #000;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #ff5c00;
  background-color: #ff5c00;
  border-radius: 8px;
  color: white;
}

/* .navbar .nav__links:hover {
  background: linear-gradient(90deg, #8e4fe9, rgb(249, 226, 226));
} */

.navbar .navs__link {
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  color: #fff;
  border-color: #e21a03;
  background: linear-gradient(90deg, #c91500, #ff412b);
}

.navbar .navs__link:hover {
  color: #fff;
  border-color: transparent;
  background: linear-gradient(90deg, #ff412b, #ff412b);
  text-decoration: none !important;
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.5rem;
  color: #40e550;
}

.navbar-brand:hover {
  color: #40e550;
}

.navbar .ms-auto {
  display: flex;
  /* align-items: center; */
  gap: 0.5rem;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler .fa-bars,
.navbar-toggler .fa-times {
  font-size: 1.5rem;
  color: #000; /* Adjust color as needed */
}

.modal-header {
  border-bottom: none;
}

.modal-title {
  font-size: 1.25rem;
}

.login__form .modal-body {
  padding:0rem 2rem 2rem 2rem;  
}

.login__form .form-control {
  border-radius: 0.5rem;
  border-color: #d9d9d9;
  box-shadow: none;
  transition: border-color 0.3s ease-in-out;
}

.login__form .form-control:focus {
  border-color: #7b7bff;
  box-shadow: 0 0 0 0.2rem rgba(123, 123, 255, 0.25);
}

.login__form .login__form-btn {
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  color: #fff;
  border-color: #ff5c00;
  background: #ff5c00;
}

.login__form .login__form-btn:hover {
  color: #fff;
  border-color: transparent;
  background: #ff5c00;
  text-decoration: none !important;
}

